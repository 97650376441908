import React, { useState } from "react";
import avatar from "../../assets/images/avatar-image.png";
import UserBtns from "./UserBtns";
import expand_more_icon from "../../assets/images/expand_more_icon.png";
import expand_less_icon from "../../assets/images/expand_less_icon.png";
import KeycloakUserService from "../../keycloak/KeycloakUserService";

const UserOptions = ({ dropdownVisible, setDropdownVisible }) => {
  const userName = KeycloakUserService.getUsername();
  const RoundImage = ({ name }) => {
    const initial1 = `${name.charAt(0)}`;
    const initial2 = `${name.charAt(1)}`;

    return (
      <div className="ml-3 w-auto sm:w-10 md:w-10 lg:w-10 flex justify-center items-center rounded-full bg-gray-500 text-white text-center p-1 text-[5px] sm:text-xs font-bold gap-[1px]">
        <span>{initial1.toUpperCase()}</span>
        <span>{initial2.toUpperCase()}</span>
      </div>
    );
  };

  return (
    <>
      {/* Toggle dropdown visibility */}
      <div
        onClick={() => setDropdownVisible(!dropdownVisible)}
        className={`flex flex-row mr-7 md:mr-4 mt-5 mb-5 p-2 border rounded-xl border-gray-300 ${
          userName.length < 15 && "w-[135px] md:w-[210px]"
        } h-[30px] md:h-[50px] cursor-pointer ${
          dropdownVisible ? "border-sky-900" : ""
        }`}
      >
        <RoundImage name={userName} />
        <div className="ml-3 mr-3 w-[50%]">
          <p className="text-[6px] md:text-sm font-bold mb-0 font-inter w-auto">
            {userName}
          </p>
          <p className="text-[5px] md:text-[10px] font-inter text-zinc-400 mt-0">
            VO User
          </p>
        </div>
        <span className="material-symbols-outlined ml-auto">
          {dropdownVisible ? (
            <img
              className="w-3 md:w-6 material-symbols-outlined mb-7 md:mb-1"
              src={expand_less_icon}
              alt="expand_less logo"
            />
          ) : (
            <img
              className="w-3 md:w-6 material-symbols-outlined mb-7 md:mb-1"
              src={expand_more_icon}
              alt="expand_more logo"
            />
          )}
        </span>
      </div>

      {/* Render UserBtns when dropdown is visible */}
      <div className={`${dropdownVisible ? "block" : "hidden"}`}>
        <UserBtns closeDropdown={() => setDropdownVisible(false)} />
      </div>
    </>
  );
};

export default UserOptions;
