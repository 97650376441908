import React, { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import { connect } from "react-redux";
import expand_more from "../../assets/images/expand_more.svg";
import expand_less from "../../assets/images/expand_less.svg";
import { qaSystemApi } from "../apiCall";
import { saveQAContent } from "../../redux/actions";

const ChatGPTBox = (props) => {
  const [featureFlag, setFeatureFlag] = useState(props.featureFlag);
  const [qaContent, setQaContent] = useState(
    props.QAContentRedux !== "" ? JSON.parse(props.QAContentRedux) : ""
  );
  const [showFullContent, setShowFullContent] = useState(false); //for show all content
  const [searchResult, setSearchResult] = useState(
    props.searchResult ? JSON.parse(props.searchResult) : []
  );
  useEffect(() => {
    console.log("featureFlag",searchResult);
    setFeatureFlag(props.featureFlag);
  }, [featureFlag, props.featureFlag]);
  const nl2br = (text) => {
    return text.replace(/(?:\r\n|\r|\n)/g, "</br>");
  };
  const QAsystem = async () => {
    const contextData =
      searchResult.length > 5
        ? [
            searchResult[0].aggregate_search_content[0],
            searchResult[1].aggregate_search_content[0],
            searchResult[2].aggregate_search_content[0],
            searchResult[3].aggregate_search_content[0],
            searchResult[4].aggregate_search_content[0],
          ]
        : [searchResult[0].aggregate_search_content[0]];
    await qaSystemApi({
      context: contextData,

      question: props.searchedWord,
    })
      .then(
        (res) => props.saveQAContent(JSON.stringify(res.data))
        //setQaContent(res.data)
      )
      .catch((err) => {
        props.saveQAContent(
          JSON.stringify({
            answer:
              "Sorry I could not provide a response, please refine your question or refer to the search results next to me",
            question: props.searchedWord,
          })
        );
      });
  };

  useEffect(() => {
    props.QAContentRedux === "" && QAsystem();
  }, [props.searchResult]);
  const handelShowContent = () => {
    if (showFullContent) {
      setShowFullContent(false);
    } else {
      setShowFullContent(true);
    }
  };

  return (
    <div className="qaenginebox">
      {featureFlag["Q&A engine"] ? (
        <div
          className={`${"h-fit"} w-[300px] shadow-md mr-14 mt-[30%] rounded-md border`}
        >
          <div className="beta-tag">
            <a class="ui yellow ribbon label">BETA</a>
          </div>
          <div className="border-b">
            <div className="m-4 flex logoclick">
              <Logo chatgpt={true} />
            </div>
          </div>
          {props.QAContentRedux !== "" ? (
            <>
              <div>
                {JSON.parse(props.QAContentRedux)?.answer?.length > 500 ? (
                  showFullContent ? (
                    <div
                      className="text-sm m-4 "
                      dangerouslySetInnerHTML={{
                        __html: nl2br(JSON.parse(props.QAContentRedux).answer),
                      }}
                    ></div>
                  ) : (
                    <div
                      className="text-sm m-4"
                      dangerouslySetInnerHTML={{
                        __html:
                          nl2br(
                            JSON.parse(props.QAContentRedux)?.answer?.substring(
                              0,
                              500
                            )
                          ) + "....",
                      }}
                    ></div>
                  )
                ) : (
                  <div
                    className="text-sm m-4"
                    dangerouslySetInnerHTML={{
                      __html: nl2br(JSON.parse(props.QAContentRedux).answer),
                    }}
                  ></div>
                )}

                {JSON.parse(props.QAContentRedux)?.answer?.length > 500 && (
                  <button
                    onClick={handelShowContent}
                    className=" mr-14 bg-[#0971CE] h-[40px] w-[120px] rounded-[20px] text-white"
                    style={{ marginBottom: "10px", marginLeft: "90px" }}
                  >
                    <span className="mr-2">
                      {" "}
                      {`${showFullContent ? "Show Less" : "Show More"}`}
                    </span>
                    <span>
                      {showFullContent ? (
                        <img
                          className="w-5 material-symbols-outlined"
                          src={expand_less}
                          alt="expand_less logo"
                        ></img>
                      ) : (
                        <img
                          className="w-5 material-symbols-outlined"
                          src={expand_more}
                          alt="expand_more logo"
                        ></img>
                      )}
                    </span>
                  </button>
                )}
              </div>
            </>
          ) : (
            <div class="dot-loader">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </div>
      ) : (
        <div> </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    searchedWord: state.searchedWord,
    searchResult: state.searchResult,
    featureFlag: state.featureFlag,
    QAContentRedux: state.QAContentRedux,
  };
};

export default connect(mapStateToProps, {
  saveQAContent,
})(ChatGPTBox);