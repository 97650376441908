import { createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from '../reducers/index';

// Configuration for redux-persist
const persistConfig = { 
  key: 'root', 
  storage 
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

// Create the Redux store with the persisted reducer and Redux DevTools extension
export const store = createStore(
  persistedReducer,
  composeWithDevTools() // Use composeWithDevTools to enable Redux DevTools
);
