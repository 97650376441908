import React from "react";
import {
  storeOutline,
  storeFileLink,
  storeDocDetail,
} from "../../redux/actions";
import { Card, Popup } from "semantic-ui-react";
import { useNavigate } from "react-router";
import push_pin from "../../assets/images/push_pin.svg";
import pdfimage from "../../assets/images/download.jpg";
import { connect } from "react-redux";
import { getSasUrl } from "../apiCall";

const PinnedDocs = (props) => {
  const navigate = new useNavigate();

  const handleView = (data) => {
    data?.procedureTitle
      ? toViewAuthDoc(data)
      : toViewDoc(
          data,
          data.id,
          data.title,
          data.file_link,
          data.outline,
          data.equipment[0],
          data.type,
          data.file_name,
          data.meta
        );
  };

  const toViewAuthDoc = (result) => {
    props.storeDocDetail({
      authorData: JSON.stringify(result),
      authdoc: true,
    });
    navigate(`/searchResultDetail/authored/${result.id}`);
  };

  const toViewDoc = (
    data,
    id,
    rootId,
    title,
    file_link,
    outline,
    equipment,
    type,
    fileName,
    meta
  ) => {
    props.storeOutline(JSON.stringify(outline));
    getSasUrl({
      url: file_link,
      directory: "",
    }).then((res) => {
      props.storeFileLink(res.data.convertedUrl);
      props.storeDocDetail({
        result: data,
        id: id,
        rootId: rootId,
        title: title,
        file_link: file_link,
        equipment: equipment,
        type: type,
        fileName: fileName,
        meta: meta,
      });
      navigate(`/searchResultDetail/ingested/${id}`);
    });
  };

  const slicingFunction = (text) => {
    if (text.length > 35) {
      return text.slice(0, 35) + "...";
    } else {
      return text;
    }
  };

  const shortenName = (name) => {
    if (name[0] === "setupInstruction") {
      return "Setup Instruction";
    } else if (name[0] === "operatingProcedure") {
      return "Operating Procedure";
    }
    if (name.length > 25) {
      return name.substring(0, 25) + "...";
    }
    return name;
  };

  return (
    <Card
      key={Math.random()}
      className={
        props.data.searchResults.status !== "APPROVED" &&
        props.data?.searchResults?.documentType
          ? "grayed-out-card"
          : "cardtot"
      }
    >
      <Card.Content className="cardheader">
        <div
          className="titleofcard"
          onClick={() => {
            handleView(props.data?.searchResults);
          }}
        >
          {props.data?.searchResults.procedureTitle
            ? slicingFunction(props.data?.searchResults.procedureTitle)
            : slicingFunction(props.data?.searchResults?.title)}
        </div>

        <div className="pinImage">
          <Popup
            content={
              props.data.pinned
                ? "Unpin this document"
                : "Unpin this document"
            }
            position="top center"
            inverted
            trigger={
              <button
                className="w-8 h-8 rounded-full"
                onClick={() => {
                  props.handlepopupOn && props.handlepopupOn(props.data);
                }}
              >
                <img
                  className={`h-7 m-[2px] rounded-full bg-[#1f97dc] absolute top-[1em] right-[1em] ${
                    props.handlepopupOn ? "cursor-pointer" : "cursor-default"
                  }`}
                  src={push_pin}
                  alt="push pin logo"
                />
              </button>
            }
          />
        </div>
      </Card.Content>

      <Card.Content extra className="pdfimagewrapper">
        <div className="pdfimage">
          {props.data?.searchResults?.procedureTitle ? (
            <div className="xauth-tag-icon">
              <a className="xauth-icon">Xauth</a>
            </div>
          ) : (
            <img src={pdfimage} alt="no image" />
          )}
        </div>
        <div className="right-div">
          <div className="filetype">
            {props.data?.searchResults?.procedureTitle
              ? "Xauthor File"
              : "Original File"}
          </div>
          <div className="linktoredirect">
            {shortenName(
              props.data?.searchResults?.procedureTitle
                ? props.data?.searchResults?.type
                : props.data?.searchResults?.file_name
            )}
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    pinnedSearches: state.pinnedSearches,
  };
};

export default connect(mapStateToProps, {
  storeDocDetail,
  storeFileLink,
  storeOutline,
})(PinnedDocs);