import React, { useEffect, useState, useRef } from "react";
import PdfHighlighterPage from "./pdfHighlighter/PdfHighlighterPage";
import { Icon, Modal, Button, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import KeycloakUserService from "../../keycloak/KeycloakUserService";
import FeedbackList from "./FeedbackList";
import {
  feedbackComment,
  feedbackCount,
  viewFeedbackComment,
} from "../../components/apiCall";
import { setToaster } from "../../redux/actions";
import findAndReplaceDOMText from "findandreplacedomtext";
import HeaderComponent from "./HeaderComponent";
import SidebarXauth from "./SidebarXauth";
import ProcedContent from "./ProcedContent";
import StepContent from "./StepContent";
import Mediafullscreen from "./Mediafullscreen";
import RenderFeedbackMediaComp from "./RenderFeedbackMediaComp";

import { appInsights } from "../../utils/appInsights.js";

const ResultSearchDetailBox = (props) => {
  let authData = props.docDetail.authorData
    ? JSON.parse(props.docDetail.authorData)
    : [];

  //const sentenceSimilarity = require("sentence-similarity");

  const [tableContents, setTableofContents] = useState(
    authData.steps ? Object.values(authData.steps) : []
  );
  let roles = KeycloakUserService.getClientRoles();
  const stringSimilarity = require("string-similarity");
  const [feedbackCollection, setFeedbackCollection] = useState([]);
  const [handleFeedbackdropdown, setHandleFeedbackdropdown] = useState(false);
  const [feedbackDropdownStep, setFeedbackdropdownStep] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [form, setForm] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const [media, setMedia] = useState("");
  const [buttonAbler, setButtonAbler] = useState(false);
  const [idToHighlight, setIdToHighlight] = useState("");
  const fileRef = useRef();
  const inputFileType = ["mov", "mp4", "jpg", "jpeg", "png"];
  const renderImageType = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/JPG",
    "image/JPEG",
    "image/PNG",
  ];
  const renderVideoTypes = [
    "video/mp4",
    "MOV",
    "mov",
    "video/quicktime",
    "video/*",
    "HEVC/H.264",
    "hevc/h.264",
  ];
  useEffect(() => {
    
    console.log(props.docDetail)
    const descs = document.getElementsByClassName("authContent");
    
    //console.log("descs", descs);
    //now search for the summary inn the description

    //find the element in descs which matches most with the summary and scroll to it, don't use findandreplaceDOMText
    //as it will replace the innerHTML of the element
    const similarities = [];
    if (descs.length !== 0)
    {
      for (let i = 0; i < descs.length; i++) {
        const desc = descs[i] ;
       
        const descSimilarities = [];
        // Get the text content of the element
        const descText = desc.textContent
        //check if its nothing but empty spaces
        if (descText.trim().length === 0) {
          continue;
        }
        // Get the summary
        const summary = authData.summary;
        console.log("summary", summary);
        
        // dynamically decide size of chunk based on the length of the summary
        const descChunks = descText.split('.').map((chunk) => chunk.trim()).filter((chunk) => chunk.length > 0);
        // create two line sentence chunks of  the summary text
        for (let j = 0; j < descChunks.length; j++) {
          const descChunk = descChunks[j];
          
          //console.log("descChunk", descChunk);
          const descSimilarity = compareStrings(descChunk, summary);
          descSimilarities.push([descSimilarity, descChunk]);
        }
        //push the highest similarity alongn with its chunk in the descSimilarities
        similarities.push(descSimilarities.reduce((acc, val) => {
          return acc[0] > val[0] ? acc : val;
        }));










        //console.log("similarity", similarity, "for", descText);
        // Push the similarity to the array

      }
    console.log("similarities", similarities);

    //search the chunk in the DOM and replace it with a highlighted version and scroll to it 
    const highestSimilarity = similarities.reduce((acc, val) => {
      return acc[0] > val[0] ? acc : val;
    }
    );
   
    findAndReplaceDOMText(
      document.getElementsByClassName("authContent")[0],
      {
        find: highestSimilarity[1],
        wrap: "span",
        wrapClass: authData.summary? "scrollToMe highlighted":"",

      }
    )
    console.log("highestSimilarity", props.docDetail);
      if (authData.summary) {
    const scrollToMe = document.getElementsByClassName("scrollToMe")[0];
    console.log("scrollToMe", scrollToMe);
    scrollToMe.scrollIntoView(
      {
        behavior: "auto",
        block: "center",
        inline: "center"
      }
    );
      }
    }
  }, []);

  function compareStrings(string1, string2) {
    const tokens1 = string1.split(' ');
    const tokens2 = string2? string2.split(' '):[];
    // Create vectors based on word occurrence
    const vector1 = tokens1.reduce((acc, word) => {
      acc[word] = (acc[word] || 0) + 1;
      return acc;
    }, {});

    const vector2 = tokens2.reduce((acc, word) => {
      acc[word] = (acc[word] || 0) + 1;
      return acc;
    }, {});
    // Get unique words from both vectors
    const uniqueWords = Array.from(new Set([...tokens1, ...tokens2]));

    // Convert vectors to arrays with 0 for missing words
    const array1 = uniqueWords.map((word) => vector1[word] || 0);
    const array2 = uniqueWords.map((word) => vector2[word] || 0);

    // Calculate cosine similarity
    const similaritycosine = cosineSimilarity(array1, array2);

    return similaritycosine;
  }

  function cosineSimilarity(vectorA, vectorB) {
    const dotProduct = vectorA.reduce((acc, value, index) => acc + value * vectorB[index], 0);
    const magnitudeA = Math.sqrt(vectorA.reduce((acc, val) => acc + val * val, 0));
    const magnitudeB = Math.sqrt(vectorB.reduce((acc, val) => acc + val * val, 0));
    return dotProduct / (magnitudeA * magnitudeB);
  }

  useEffect(() => {
    getFeedbackListCount();
    props.docDetail.authdoc && searchedWordInDescription();
  }, [buttonAbler]);

  const StepToBeHighlighted = (id) => {
    setIdToHighlight(id);
  };
  const handleFeedback = (steps) => {
    setHandleFeedbackdropdown(
      (handleFeedbackdropdown) => !handleFeedbackdropdown
    );
    setFeedbackdropdownStep(steps);
  };
  const getFeedbackListCount = async () => {
    await feedbackCount(authData.id)
      .then((res) => {
        setFeedbackCollection(
          res.data.data.length > 0 ? res.data.data : [{ stepId: "stepId" }]
        );
      })
      .catch(() => {
        props.setToaster(true);
      });
  };

  const addButtonDisableFunc = (stepId) => {
    let isMyComments;

    feedbackCollection.map((feedback) => {
      if (feedback.stepId === stepId) {
        isMyComments = feedback?.myComments?.length > 0 ? true : false;
      }
    });

    return isMyComments;
  };
  const viewButtonDisableFunc = (stepId) => {
    let isTotalComments;
    console.log("stepId", stepId);

    feedbackCollection.map((feedback) => {
      if (feedback.stepId === stepId) {
        isTotalComments = feedback?.totalComments?.length > 0 ? true : false;
      }
    });

    return !isTotalComments;
  };
  const addFeedback = (e) => {
    e.preventDefault();
    setHandleFeedbackdropdown(false);
    setShowFeedbackPopup(true);
  };
  const viewFeedback = async () => {
    await viewFeedbackComment(authData.documentId, feedbackDropdownStep.stepId)
      .then((res) => {
        res.data.data.length === 0 && alert("No feedback found");

        setFeedbackList(res.data.data);
        setHandleFeedbackdropdown(false);
        res.data.data.length !== 0 && setPopupVisible(true);
      })
      .catch(() => {
        props.setToaster(true);
      });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmitFeedback = async (e, steps) => {
    e.preventDefault();

    if (form.feedBackTitle && form.category && form.feedbackDescription) {
      saveFeedback(steps);
    } else {
      alert("Enter all the fields");
    }
  };
  const saveFeedback = (steps) => {
    if (
      form.feedBackTitle.length > 0 &&
      form.category.length > 0 &&
      form.feedbackDescription.length > 0
    ) {
      var data = {
        documentId: authData.documentId,
        procedureTitle: authData.procedureTitle,
        stepId: steps.stepId,
        section: steps.stepTitle,
        summaryOfFeedback: form.feedBackTitle,
        brief: form.feedbackDescription,
        typeOfFeedback: form.category,
        uploadedMedia: fileDetails,
      };

      const formData = createFormData(data);
      feedbackComment(formData)
        .then((res) => {
          setShowFeedbackPopup(false);
          setFileDetails([]);
          setForm({});

          setButtonAbler((buttonAbler) => !buttonAbler);
          appInsights.trackEvent({ 
              name: "feedback", 
              properties: { 
                documentId: data.documentId,
                title: data.procedureTitle,
                stepId: steps.stepId,
                step: steps.stepTitle,
                summaryOfFeedback: form.feedBackTitle,
                // brief: form.feedbackDescription,
                typeOfFeedback: form.category,
                username: KeycloakUserService.getUsername() 
              } 
          });
        })
        .catch(() => {
          setShowFeedbackPopup(false);
          setFileDetails([]);
          setForm({});
          props.setToaster(true);
        });
    } else {
      alert("Enter every fields");
    }
  };
  // Create formdata to save step
  const createFormData = (data) => {
    const formData = new FormData();
    const uploadedMedia = data.uploadedMedia;
    let stepDetail = {
      documentId: authData.documentId,
      procedureTitle: authData.procedureTitle,
      stepId: data.stepId,
      section: data.section,
      summaryOfFeedback: data.summaryOfFeedback,
      brief: data.brief,
      typeOfFeedback: data.typeOfFeedback,
    };

    formData.append("data", JSON.stringify(stepDetail));
    if (uploadedMedia && uploadedMedia.length > 0) {
      for (let i = 0; i < uploadedMedia.length; i++) {
        formData.append("media", uploadedMedia[i]);
      }
    }
    return formData;
  };

  const FeedBackTypeList = [
    {
      label: "Technical improvement",
      value: "Technical improvement",
    },
    {
      label: "Grammatical correction",
      value: "Grammatical correction",
    },
    {
      label: "Technical correction",
      value: "Technical correction",
    },

    {
      label: "Additional information",
      value: "Additional information",
    },
  ];
  const renderUploadFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    fileRef.current.click();
  };
  const handleInputChange = (e) => {
    e.preventDefault();
    const fileData = e.target.files;
    for (let i = 0; i < fileData.length; i++) {
      if (validateFile(fileData[i])) {
        if (!fileData[i].name) {
          const binary = atob(fileData[i].split(",")[1]);
          const array = [];
          var file;
          for (let i = 0; i < binary.length; i += 1) {
            array.push(binary.charCodeAt(i));
          }
          file = new Blob([new Uint8Array(array)], {
            type: "image/png",
          });
          file.name = `${new Date()}.png`;
          file.lastModifiedDate = new Date();
          const myFile = new File([file], file.name, {
            type: file.type,
          });
          setFileDetails((fileDetails) => [...fileDetails, myFile]);
        }
        const fileChild = document.getElementById("files");
        const url = window.URL.createObjectURL(new Blob([fileData[i]]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.png");
        fileChild.appendChild(link);
        setFileDetails((fileDetails) => [...fileDetails, fileData[i]]);
      }
    }
  };

  const validateFile = (fileData) => {
    const fileType = fileData.name.split(".").pop().toLowerCase();
    if (fileData.size > 100 * 1024 * 1024) {
      const fileSize = (fileData.size / 1024 / 1024).toFixed(2);
      alert(
        "The attached file (" +
        fileSize +
        ") exceeds the maximum size limit of 100 MB. Please reduce the file size and try again."
      );
      return false;
    } else if (!inputFileType.includes(fileType)) {
      alert(
        "We do not accept files of this media type. Only file with png, jpg, jpeg, mp4 and mov are allowed."
      );
      return false;
    } else {
      const isFileExist = fileDetails?.some(
        (file) => file.name === fileData.name
      );

      if (isFileExist) {
        alert(
          "The file you are trying to upload has the same name as an existing file. Please rename the file or choose a different one."
        );
        return false;
      }
    }
    return true;
  };

  const renderFeedbackMedia = () => {
    var displayMedia = [];
    if (fileDetails?.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (renderImageType.includes(fileDetails[i].type)) {
          displayMedia.push(
            <RenderFeedbackMediaComp
              fileDetails={fileDetails[i]}
              mediaFullScreen={mediaFullScreen}
              removeFile={removeFile}
              Image={true}
            />
          );
        }

        if (renderVideoTypes.includes(fileDetails[i].type)) {
          displayMedia.push(
            <RenderFeedbackMediaComp
              fileDetails={fileDetails[i]}
              mediaFullScreen={mediaFullScreen}
              removeFile={removeFile}
              Image={false}
            />
          );
        }
      }
    }
    return displayMedia;
  };
  const removeFile = (fileName, collectionName) => {
    const fileUpdatedCollection = [];
    switch (collectionName) {
      case "fileDetails":
        if (fileDetails.length > 0) {
          for (let i = 0; i < fileDetails.length; i++) {
            if (fileDetails[i].name !== fileName) {
              fileUpdatedCollection.push(fileDetails[i]);
            }
          }
          setFileDetails(fileUpdatedCollection);
        }
        break;

      default:
        break;
    }
  };
  const searchedWordInDescription = () => {
    for (let i = 0; i < tableContents.length + 1; i++) {
      if (
        document.getElementsByClassName("proced-desc") &&
        props.searchedWord !== ""
      ) {
        // findAndReplaceDOMText(
        //   document.getElementsByClassName("proced-desc")[i],
        //   {
        //     find: props.searchedWord,
        //     wrap: "span",
        //     wrapClass: "highlighted",
        //   }
        // );
      }
    }
  };
  const mediaFullScreen = (media) => {
    setMedia(media);
  };

  return props.docDetail.authdoc ? (
    <div className="ml-14 mr-14 mt-5 pb-6 w-[97%] ml-[2%] ">
      <HeaderComponent
        title={authData.procedureTitle[0]}
        category={authData.category}
        type={authData.documentType}
        result={authData}
        fileName={"NO FILE AVAILABLE"}
        fileLink={"NO LINK"}
        dualDoc={false}
      />

      <br />
      <div className=" bg-white border border-gray-300 rounded-md flex">
        <SidebarXauth
          tableContents={tableContents}
          StepToBeHighlighted={StepToBeHighlighted}
          docToFindHighlight={authData}
        />

        <div className="authContent">
          <ProcedContent
            title={authData.procedureTitle[0]}
            description={authData.procedureDescription[0]}
            media={authData.media}
          />

          {tableContents.map((steps) => {
            return (
              <>
                <div className="add-feedback">
                  <Modal
                    className="modal-upload-delete"
                    open={showFeedbackPopup}
                    onClose={() => setShowFeedbackPopup(false)}
                  >
                    <Modal.Header>Feedback </Modal.Header>
                    <Modal.Content>
                      <div className="feedbackform-container">
                        <select
                          id="category-drop"
                          name="category"
                          placeholder="Type of Feedback"
                          onChange={handleChange}
                          className={"category-dropdown"}
                        >
                          {FeedBackTypeList.map((option) => (
                            <>
                              <option key="blankKey" hidden value="">
                                Type of Feedback
                              </option>
                              <option value={option.value}>
                                {option.label}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                      <div className="feedbackform-container">
                        <input
                          maxLength={300}
                          style={{ paddingLeft: "5px" }}
                          name="feedBackTitle"
                          placeholder={"Summary of the feedback"}
                          className={"category-dropdown"}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="feedbackform-container">
                        <textarea
                          maxLength={500}
                          style={{ paddingLeft: "5px", paddingTop: "5px" }}
                          name="feedbackDescription"
                          className={"feedback-description"}
                          placeholder={"Brief the information"}
                          onChange={handleChange}
                        />
                      </div>
                      <span className="instruction-upload-media">
                        Only file with png, jpg, jpeg, mp4 and mov are allowed
                      </span>
                      <Icon
                        name="attach"
                        id="attach"
                        className="dropdown-icon"
                        onClick={renderUploadFile}
                      />
                      <input
                        type="file"
                        id="video"
                        className="takefile"
                        name="media"
                        multiple
                        accept="image/png, image/jpeg, image/jpg, video/mp4, image/*, video/*, HEVC/H.265, HEVC/H.264, JPEG/H.264, MOV, mov"
                        ref={fileRef}
                        onChange={handleInputChange}
                      />
                      <div id="files">
                        <ul style={{ listStyle: "none" }}>
                          {renderFeedbackMedia().length > 0 &&
                            fileDetails?.length > 0 && (
                              <li>{renderFeedbackMedia()}</li>
                            )}
                        </ul>
                      </div>
                    </Modal.Content>
                    <div className="feedback-btn-wrap">
                      <Button
                        className="secondaryButton"
                        onClick={() => {
                          setShowFeedbackPopup(false);
                          setFileDetails([]);
                          setForm({});
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="primaryButton"
                        onClick={(e) =>
                          handleSubmitFeedback(e, feedbackDropdownStep)
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </Modal>
                </div>
                <StepContent
                  steps={steps}
                  roles={roles.includes("user")}
                  handleFeedback={handleFeedback}
                  handleFeedbackdropdown={handleFeedbackdropdown}
                  feedbackDropdownStep={feedbackDropdownStep}
                  feedbackCollection={feedbackCollection}
                  addFeedback={addFeedback}
                  addButtonDisableFunc={addButtonDisableFunc}
                  viewFeedback={viewFeedback}
                  viewButtonDisableFunc={viewButtonDisableFunc}
                  idToHighlight={idToHighlight}
                />
              </>
            );
          })}

          {popupVisible && (
            <Modal
              className="modal-upload-delete"
              open={popupVisible}
              onClose={() => setPopupVisible(false)}
            >
              <Modal.Header>Feedback </Modal.Header>
              <div className="feedback-content-wrapup">
                <Modal.Content>
                  <FeedbackList
                    feedbackList={feedbackList}
                    mediaFullScreen={mediaFullScreen}
                  />
                </Modal.Content>
              </div>
              <Modal.Actions>
                <Button
                  className="secondaryButton"
                  onClick={() => setPopupVisible(false)}
                >
                  Cancel
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </div>
      </div>
      <div className="fullscreen-container">
        <Modal className="modal-upload-image" open={media}>
          <button onClick={() => setMedia("")} className="image-close">
            X
          </button>
          <Mediafullscreen media={media} />
        </Modal>
      </div>
    </div>
  ) : (
    //no-print classname to the ingested document which hides all the content when trying to print
    <>
      <div
        className={`ml-14 mr-14 mt-5  pb-6 w-[97%] ml-[2%] ${props.docDetail.dual ? "no-print" : ""
          }`}
      >
        <HeaderComponent
          result={props.docDetail.result}
          id={props.docDetail.id}
          title={props.docDetail.title}
          category={props.docDetail.equipment}
          type={props.docDetail.type}
          fileName={props.docDetail.fileName}
          fileLink={props.docDetail.file_link}
          dualDoc={props.docDetail.dual}
        />

        <br />
        <div className=" bg-white border border-gray-300 rounded-md">
          <PdfHighlighterPage noPrint={props.docDetail.dual} />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    fileLinkRedux: state.fileLinkRedux,
    docDetail: state.docDetail,
    searchedWord: state.searchedWord,
  };
};

export default connect(mapStateToProps, { setToaster })(ResultSearchDetailBox);
