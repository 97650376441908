import React, { useState, useEffect } from "react";
import { Accordion, Icon } from "semantic-ui-react";

function SidebarXauth({ tableContents, StepToBeHighlighted, docToFindHighlight }) {
  const [clickedToc, setClickedToc] = useState();

  function jaccardSimilarity(str1, str2) {
    const set1 = new Set(str1.split(' '));
    const set2 = new Set(str2.split(' '));

    const intersection = new Set([...set1].filter(word => set2.has(word)));
    const union = new Set([...set1, ...set2]);

    const similarity = intersection.size / union.size;
    return similarity;
  }
  // Function to calculate the dot product of two vectors
const dotProduct = (vec1, vec2) => {
  return vec1.reduce((sum, value, index) => sum + value * vec2[index], 0);
};

// Function to calculate the magnitude of a vector
const magnitude = (vec) => {
  return Math.sqrt(vec.reduce((sum, value) => sum + value * value, 0));
};
  // Function to calculate the cosine similarity between two vectors
  const cosineSimilarity = (vec1, vec2) => {
    const dot = dotProduct(vec1, vec2);
    const mag1 = magnitude(vec1);
    const mag2 = magnitude(vec2);

    if (mag1 === 0 || mag2 === 0) {
      return 0; // Handle division by zero
    }

    return dot / (mag1 * mag2);
  };

  function preModifyCosinetransform(string1, string2) {
    // Tokenize strings into arrays of words
    const tokens1 = string1.split(' ');
    const tokens2 = string2.split(' ');
    // Create vectors based on word occurrence
    const vector1 = tokens1.reduce((acc, word) => {
      acc[word] = (acc[word] || 0) + 1;
      return acc;
    }, {});

    const vector2 = tokens2.reduce((acc, word) => {
      acc[word] = (acc[word] || 0) + 1;
      return acc;
    }, {});
    // Get unique words from both vectors
    const uniqueWords = Array.from(new Set([...tokens1, ...tokens2]));

    // Convert vectors to arrays with 0 for missing words
    const array1 = uniqueWords.map((word) => vector1[word] || 0);
    const array2 = uniqueWords.map((word) => vector2[word] || 0);

    // Calculate cosine similarity
    const similaritycosine = cosineSimilarity(array1, array2);

    return similaritycosine;
  }

  let highestMatchingStepId = '';
  let highestSimilarity = 0;
  let summaryToHighlight = docToFindHighlight.summary? docToFindHighlight.summary.replace(/<[^>]*>/g, ''):'';
  if (docToFindHighlight.steps && docToFindHighlight.steps.length) {
    let procedureToCompare = `${docToFindHighlight.procedureTitle[0]} ${docToFindHighlight.procedureDescription[0]}`;
    procedureToCompare = procedureToCompare.replace(/<[^>]*>/g, '');
    let procedureSimilarity = preModifyCosinetransform(summaryToHighlight, procedureToCompare);
    for (let i = 0; i < docToFindHighlight.steps.length; i++) {
      let stepToCompare = `${docToFindHighlight.steps[i].stepTitle[0]} ${docToFindHighlight.steps[i].stepDescription[0]}`;
      stepToCompare = stepToCompare.replace(/<[^>]*>/g, '');
      let similarity = preModifyCosinetransform(summaryToHighlight, stepToCompare);
      if (similarity > highestSimilarity) {
        highestSimilarity = similarity;
        highestMatchingStepId = docToFindHighlight.steps[i].stepId[0];
      }
    }
    if (procedureSimilarity > highestSimilarity) {
      highestMatchingStepId = '';
    }
  }
  useEffect(() => {
    if (highestMatchingStepId !== '') {
      updateHash(highestMatchingStepId);
    }
  }, []);
  const updateHash = (id) => {
    StepToBeHighlighted(docToFindHighlight.summary?id:'');
    setClickedToc(id);
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: "auto",
      });
    }
  };
  return (
    <div className="sidebar">
      <Accordion>
        <Accordion.Content active={true}>
          <ul className="sidebar-ul">
            {tableContents.map((steps, index) => {
              return (
                <div>
                  {true && (
                    <div
                      className="tableOfContentsauth"
                      style={{
                        backgroundColor:
                          clickedToc === steps.stepId ? "#EEF5FC" : "",
                      }}
                      onClick={() => {
                        updateHash(steps.stepId);
                      }}
                    >
                      <div className="sidebarContent">
                        <li key={index} className="sidebar__highlightauth">
                          <div>
                            <blockquote
                              className="sidebar-text"
                              style={{
                                color:
                                  clickedToc === steps.stepId ? "#034E91" : "",
                              }}
                            >
                              {steps.stepTitle}
                            </blockquote>
                            <br />
                          </div>
                        </li>
                      </div>

                      <div className="sidebarContent">
                        <Icon
                          name={"chevron right"}
                          className="arrow-icon-auth"
                          style={{
                            color: clickedToc === steps.stepId ? "#034E91" : "",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </ul>
        </Accordion.Content>
      </Accordion>
    </div>
  );
}

export default SidebarXauth;