// action for save search results
export const saveSearchResult = (result) => {
  return {
    type: "SAVE_SEARCH_RESULT",
    payload: result,
  };
};
// action for saving voauther documents
export const saveSearchResultAuthDocs = (result) => {
  return {
    type: "SAVE_SEARCH_AUTHDOCS_RESULT",
    payload: result,
  };
};
// action for saving all pinned searches of a user
export const pinDoc = (result) => {
  return {
    type: "PIN_SEARCH_RESULT",
    payload: result,
  };
};

// action for removing a pinned document
export const unpinDoc = (pinId) => {
  return {
    type: "UNPIN_SEARCH_RESULT",
    payload: pinId,
  };
};

// action for save searchkeyword
export const saveSearchWord = (result) => {
  return {
    type: "SAVE_SEARCH_WORD",
    payload: result,
  };
};
export const saveKnowledgeSourceFilter = (result) => {
  return {
    type: "SAVE_KNWLDG_SRC",
    payload: result,
  };
};

export const saveSpellcorrection = (result) => {
  return {
    type: "SAVE_SPELL_CORRECTION",
    payload: result,
    
  };
};

export const savefilteroplength = (result) => {
  return {
    type: "SAVE_FILTER_OP_LENGTH",
    payload: result,
  };
};
export const saveRelatedEquipmentFilter = (result) => {
  return {
    type: "SAVE_RLTD_EQPMNT",
    payload: result,
  };
};
export const storeFileLink = (fileLink) => {
  
  return {
    type: "STORE_FILELINK",
    payload: fileLink,
  };
};
export const storeOutline = (outLine) => {
  return {
    type: "STORE_OUTLINE",
    payload: outLine ? outLine : null,
  };
};
// action for saving a single doc details for showing in details page
export const storeDocDetail = (detail) => {
  return {
    type: "STORE_DOC_DETAIL",
    payload: detail,
  };
};
export const setFeatureflag = (featureflag) => {
  return {
    type: "SET_FEATURE_FLAG",
    payload: featureflag,
  };
};

// action for storing count of total documents fetched for pagination
export const setTotalDoc = (number) => {
  return {
    type: "SET_TOTAL_DOC",
    payload: number,
  };
};

export const setSearchId = (id) => {
  return {
    type: "SET_SEARCH_ID",
    payload: id,
  };
}
export const setActivePage = (number) => {
  return {
    type: "SET_ACTIVE_PAGE",
    payload: number,
  };
};
// action for saving list of equipments from backend to view in filter dropdown
export const setEquipment = (equipments) => {
  return {
    type: "SET_EQUIPMENTS",
    payload: equipments,
  };
};
// action for saving knowledge source from backend to view in filter dropdown
export const setTypes = (types) => {
  return {
    type: "SET_TYPES",
    payload: types,
  };
};
// action for saving saving a single document while pinning for storing pinned in mongodb
export const setPinnedSearches = (pinnedSearches) => {
  return {
    type: "SET_PINNEDSEARCH",
    payload: pinnedSearches,
  };
};

// export const setFeedbackView = (feedback) => {
//   return {
//     type: "SET_FEEDBACK_VIEW",
//     payload: feedback,
//   };
// };
// action for adding filter values
export const addfilter = (value) => {
  return {
    type: "ADD_FILTER",
    payload: value,
  };
};

// action for adding routing paths for
export const addPath = (value) => {
  return {
    type: "ADD_PATH",
    payload: value,
  };
};
//action to save QAContent
export const saveQAContent = (value) => {
  return {
    type: "SAVE_QA_CONTENT",
    payload: value,
  };
};
//action to save QAContent
export const setToaster = (value) => {
  return {
    type: "SET_TOASTER",
    payload: value,
  };
};
