import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import push_pin from "../../assets/images/push_pin.svg";
import { useNavigate } from "react-router-dom";
import { Loader, Popup } from "semantic-ui-react";
import { logUserAction, getHighlight } from "../apiCall";
import { addPinDocument, deletePinDocument } from "../apiCall";
import {
  storeDocDetail,
  storeOutline,
  storeFileLink,
  pinDoc,
  unpinDoc,
} from "../../redux/actions";
import { getSasUrl } from "../apiCall";
import axios from "axios";

const DocumentResultDisplay = (props) => {
  const [disableButton1, setDisableButton1] = useState(false);
  const [disableButton2, setDisableButton2] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("props.pinnedDocs", props.pinnedDocuments);
  }, []);

  const toViewAuthDoc = (result) => {
    props.storeDocDetail({
      authorData: JSON.stringify(result),
      summary: result.summary,
      authdoc: true,
    });
    if (result.document_type == "authored")
      navigate(`/searchResultDetail/authored/${result.id}`);
  };

  const getType = (type) => {
    const typeMap = {
      setupInstruction: "Setup Instruction",
      operatingProcedure: "Operating Procedure",
      promotionalMaterial: "Promotional Material",
      technicalNote: "Technical Note",
    };
    return typeMap[type] || type;
  };

  // const pinButtonClick = (result) => {
  //   const data = {
  //     ownerId: props.user,
  //     documentId: result.id,
  //     searchResults: result,
  //   };
  //   addPinDocument(data)
  //     .then(() => {
  //       setDisableButton1(true);
  //       setDisableButton2(false);
  //       setPinned(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const pinButtonClick = (result) => {
    if (props.pinnedSearches.length < 10) {
      const data = {
        ownerId: props.user,
        documentId: result.id,
        searchResults: result,
      };
  
      addPinDocument(data)
        .then(() => {
          setDisableButton1(true);
          setDisableButton2(false);
          setPinned(true);
        })
    } else {
      alert(
        "You have reached the maximum limit of pinned documents. Please unpin some documents before pinning new ones."
      );
    }
  };
  

  const unPinButtonClick = (pinId) => {
    deletePinDocument(pinId)
      .then(() => {
        setDisableButton1(false);
        setDisableButton2(true);
        setPinned(false);
      })
      .catch(() => {
        props.setToaster(true);
      });
  };

  const handlePdfView = (linkpdf, dualDoc) => {
    setLoader(true);
    getSasUrl({ url: linkpdf, directory: props.result.directory })
      .then((res) => {
        setLoader(false);
        if (!dualDoc) window.open(res.data.convertedUrl, "_blank");
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const toViewDoc = (
    result,
    id,
    rootId,
    title,
    file_link,
    outline,
    equipment,
    type,
    fileName,
    meta,
    dual,
    summary
  ) => {
    logUserAction({
      id,
      rootId,
      documentId: id,
      query: props.searchedWord,
      route: "opendocument",
      documentType: "ingested",
      documentTitle: title,
      searchId: props.searchId,
    });
    props.storeOutline(JSON.stringify(outline));
    // getSasUrl({ url: file_link, directory: props.result.directory }).then((res) => {
    //   props.storeFileLink(res.data.convertedUrl);

    
    props.storeDocDetail({
      result,
      id,
      rootId,
      title,
      file_link,
      equipment,
      type,
      fileName,
      meta: undefined,
      dual,
    });
    navigate(`/searchResultDetail/ingested/${id}`)
  };

  const decodeEntities = (html) => {
    const element = document.createElement("div");
    element.innerHTML = html;
    return element.innerText;
  };

  const removeTags = (str) => {
    let summary = typeof str === "string" ? str : "";
    let searchQuery = props.searchedWord || "";
    let queryArray = searchQuery.split(" ");

    function removeTag(str) {
      if (typeof str !== "string") return "";
      return str.replace(/(<([^>]+)>)/gi, "").substring(0, 300) + "...";
    }

    summary = removeTag(summary);
    queryArray.forEach((query) => {
      summary = summary.replace(query, `<b>${query}</b>`);
    });

    return props.getFirstTwoSentence(summary);
  };

  const categoryList = (categoryArray) => {
    if (!Array.isArray(categoryArray)) {
      return "";
    }
    return categoryArray.join(", ");
  };

  const isPinned = (id) => {
    return props.pinnedDocuments?.find((item) => {
      console.log("item", item);
      setPinned(item.documentId == id);
      console.log("pinned", item.documentId == id);
      return item.documentId == id;
    });
  };

  const renderPinButton = (result) => {
    console.log("result", result.id);
    return pinned ? (
      <div className="pinImage">
        <Popup
          trigger={
            <button
              className="ml-auto w-8 h-8"
              disabled={disableButton2 || props.apiCallInProgress}
              onClick={() => unPinButtonClick(result.id)}
            >
              <img
                className="h-7 m-[2px] rounded-full bg-[#1f97dc] right-[4em] md:right-[26.5em]"
                src={push_pin}
                alt="push pin logo"
              />
            </button>
          }
          content="Unpin this document from your homepage"
          position="top center"
          inverted
        />
        {disableButton2 && (
          <div className="xauth-loader-pin">
            <Loader active inline />
          </div>
        )}
      </div>
    ) : (
      <div className="pinImage">
        <Popup
          trigger={
            <button
              className="ml-auto w-8 h-8"
              disabled={disableButton1 || props.apiCallInProgress}
              onClick={() => pinButtonClick(result)}
            >
              <img
                className="h-7 m-[2px] rotate-45 rounded-full bg-gray-200 right-[4em] md:right-[26.5em]"
                src={push_pin}
                alt="push pin logo"
              />
            </button>
          }
          content="Pin this document to your homepage"
          position="top center"
          inverted
        />
        {disableButton1 && (
          <div className="xauth-loader-pin">
            <Loader active inline />
          </div>
        )}
      </div>
    );
  };

  const result = props.result || {};
  const procedureTitle = result.procedureTitle || [];
  const equipment = result.equipment || [];
  const procedureDescription = result.procedureDescription || [];
  useEffect(() => {
    console.log("result", result);
    isPinned(result.id);
  }, []);

  return (
    <div
      className={
        result.status &&
        result.status !== "APPROVED" &&
        result.document_type == `authored`
          ? "border shadow-md rounded-md ml-14 mr-14 mt-6 md:w-[83.34em] greyed-out"
          : "border shadow-md rounded-md ml-14 mr-14 mt-6 md:w-[83.34em]"
      }
      key={result.id}
    >
      <div className="p-5 border-b-2">
        <div className="flex font-semibold text-lg mb-4 titleandpin">
          <div className="xauth-tag">
            <a
              className={`ui ${
                result.document_type === "authored" ? "blue" : "orange"
              } ribbon label`}
            >
              {result.document_type === "authored" ? "authored" : "ingested"}
            </a>
          </div>
          <div className="overflow-hidden overflow-ellipsis w-[100%]">
            {result.title || procedureTitle[0]}{" "}
            {props.result.status !== "APPROVED" &&
            result.document_type == `authored` ? (
              <a style={{ fontSize: "small" }}>Document update in progress</a>
            ) : (
              ""
            )}
            {props.result.status !== "APPROVED" &&
            result.document_type == `authored` ? (
              <span class="loading-bars">
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
                <div class="bar"></div>
              </span>
            ) : (
              ""
            )}
          </div>
          <div>
            <br />
          </div>
          {renderPinButton(result)}
        </div>
        <div className="flex">
          <div className="p-1 pl-4 pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-[9px] md:text-sm">
            Category :{" "}
            {result.document_type === "authored"
              ? result?.equipment
              : categoryList(equipment || [result?.equipment])}
          </div>
          <div className="ml-3 p-1 pl-4 pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-[9px] md:text-sm">
            Knowledge Source: {getType(result.type || result.type)}
          </div>
        </div>
        {/* Conditionally render the Original file section */}
        {result.document_type !== "authored" && (
          <div className="mt-3 p-1 pl-4 pr-4 rounded-3xl bg-blue-100 w-fit border-2 text-[#0971CE] border-[#0971CE] text-[9px] md:text-sm flex align-middle">
            <span className="material-symbols-outlined text-[20px]">link</span>
            <span className="ml-3">
              Original file:{" "}
              {loader ? (
                <Loader size="small" active inline />
              ) : (
                <span
                  className={`${
                    props.result.dual ? "" : "underline cursor-pointer"
                  } `}
                  onClick={(e) =>
                    handlePdfView(props.result.file_link, props.result.dual)
                  }
                >
                  {props.result.dual
                    ? "NO FILE AVAILABLE"
                    : props.result.file_name}
                </span>
              )}
            </span>
          </div>
        )}
      </div>

      <div className="p-5" style={{ cursor: "pointer" }}>
        <Popup
          trigger={
            <div
              style={{
                cursor:
                  (result.status && result.status === "APPROVED") ||
                  result.document_type === `ingested`
                    ? "pointer"
                    : "default",
              }}
              onClick={() => {
                result.summary = decodeEntities(result.summary || "");
                if (result.document_type === "authored") {
                  console.log("authored", props.searchId);
                  logUserAction({
                    id: result.id,
                    rootId: result.id,
                    documentId: result.id,
                    query: props.searchedWord,
                    route: "opendocument",
                    documentType: "authored",
                    documentTitle: result.title,
                    searchId: props.searchId,
                  });
                  toViewAuthDoc(result);
                } else {
                  toViewDoc(
                    result,
                    result.id,
                    result.rootId,
                    result.title,
                    result.file_link,
                    result.outline,
                    result.equipment,
                    result.documentType,
                    result.fileName,
                    result.meta,
                    result.dualDoc,
                    result.summary
                  );
                }
              }}
            >
              {result.summary ? (
                <div
                  className="text-sm"
                  style={{ fontFamily: "Inter" }}
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(result.summary) || "",
                  }}
                />
              ) : (
                <div
                  className="text-sm"
                  style={{ fontFamily: "Inter" }}
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(
                      removeTags(procedureDescription[0] || "")
                    ),
                  }}
                />
              )}
            </div>
          }
          content={
            result.status === "APPROVED" || result.document_type === `ingested`
              ? "Click to view more details"
              : "This document cannot be viewed"
          }
          position="top center"
          inverted
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchedWord: state.searchedWord,
  pinnedDocs: state.pinnedSearchResult,
  docDetail: state.docDetail,
  searchId: state.searchId,
  pinnedSearches: state.pinnedSearches,
});

export default connect(mapStateToProps, {
  storeDocDetail,
  storeOutline,
  storeFileLink,
  pinDoc,
  unpinDoc, // Ensure this action is correctly imported
})(DocumentResultDisplay);