import React, { useEffect } from "react";

const ProcedContent = ({ title, description, media }) => {
  useEffect(() => {
    
  }, []);
  return (
    <div>
      <div className="procedsection">
        <h1 className="procedTitle">{title}</h1>
        <hr />
        <span>
          <div
            className="proced-desc"
            id="proced"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
        </span>
        <br />

        {media?.map((media) => (
          <div className="video">
            <br />
            <video src={media} controls />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProcedContent;
