import React, { useState } from "react";
import Logo from "../Logo/Logo";
import UserOptions from "../../components/Useroptions/Useroptions";
import { Popup } from "semantic-ui-react";
import { useNavigate } from "react-router";

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="navbar">
      <div className="ml-[1.1rem]  md:ml-2 mt-7 md:mt-5">
        <Logo />
      </div>
      <div className={`flex flex-row ml-auto block`}></div>
      <div className={`ml-auto`}>
        {!dropdownVisible && (
          <Popup
            content="User Options"
            position="bottom center"
            inverted
            trigger={
              <div>
                <UserOptions
                  dropdownVisible={dropdownVisible}
                  setDropdownVisible={setDropdownVisible}
                />
              </div>
            }
          />
        )}
        {dropdownVisible && (
          <div>
            <UserOptions
              dropdownVisible={dropdownVisible}
              setDropdownVisible={setDropdownVisible}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
