import React, { useEffect, useRef, useState } from "react";
import Spinner from "./Spinner";

import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";
import { connect } from "react-redux";
import { logUserAction, getHighlight } from "../../apiCall";
import Sidebar from "./Sidebar";

const PdfHighlighterPage = (props) => {
  console.log(props);
  let outLine = JSON.parse(props.outLineRedux);
  const [loading, setLoading] = useState(true);
  const pdfRef = React.createRef();
  const HighlightPopup = null;
  let [highlightText, setHighlightText] = useState([]);
  let tableContents = [];
  const scrollViewerTo = useRef({});
  //suppoed to be in onScrollCHange event removes since it is reseting the hash while scrolling causing a glitch.
  // const resetHash = () => {
  //   //document.location.hash = "";
  //   // let x = "r6bc3600e8192462aad86d9113077037a5";
  //   // document.location.hash = `highlight-${x}`;
  // };
  useEffect(() => {
    console.log(props.docDetail)
    const handleBeforePrint = (event) => {
      showPrintAlert();
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "p") {
        if (props.noPrint) {
          event.preventDefault();
          showPrintAlert();
        }
      }
    };

    window.addEventListener("beforeprint", handleBeforePrint);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("beforeprint", handleBeforePrint);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    //get the element by classname PdfHighlighter


  }, [pdfRef.current])

  function showPrintAlert() {
    props.noPrint && alert("Can't print. Printing is disabled.");
  }

  const getRectValues = (element) => {
    let rectValues = [];
    if (element.meta.id) {
      rectValues.push({
        height: element.meta.height,
        width: element.meta.width,
        x1: element.meta.x1,
        x2: element.meta.x2,
        y1: element.meta.y1,
        y2: element.meta.y2,
        pageNumber: element.meta.pageNumber,
      });
    } else if (element.meta.length > 0) {
      for (let i = 0; i < element.meta.length; i++) {
        rectValues.push({
          height: element.meta[i].height,
          width: element.meta[i].width,
          x1: element.meta[i].x1,
          x2: element.meta[i].x2,
          y1: element.meta[i].y1,
          y2: element.meta[i].y2,
          pageNumber: element.meta[i].pageNumber,
        });
      }
    }
    return rectValues;
  };

  const getBoundingRect = (element) => {
    if (element.meta.id) {
      const value = {
        height: element.meta.height,
        width: element.meta.width,
        x1: element.meta.x1,
        x2: element.meta.x2,
        y1: element.meta.y1,
        y2: element.meta.y2,
        pageNumber: element.meta.pageNumber,
      };
      return value;
    } else if (element.meta.length > 0) {
      const value = {
        height: element.meta[0].height,
        width: element.meta[0].width,
        x1: element.meta[0].x1,
        x2: element.meta[0].x2,
        y1: element.meta[0].y1,
        y2: element.meta[0].y2,
        pageNumber: element.meta[0].pageNumber,
      };
      return value;
    }
  };

  //needed tableContents to anchor , the data in it will only be anchored , so pushing the serach content for anchoring on the loading of the page
  if (props.docDetail.meta) {
    tableContents.push({
      comment: {
        emoji: "",
        text: "",
      },
      content: {
        text: "Summary from search display",
      },
      position: {
        boundingRect: {
          height: props.docDetail.meta[0].height,
          width: props.docDetail.meta[0].width,
          x1: props.docDetail.meta[0].x1,
          x2: props.docDetail.meta[0].x2,
          y1: props.docDetail.meta[0].y1,
          y2: props.docDetail.meta[0].y2,
          pageNumber: props.docDetail.meta[0].pageNumber,
        },
        rects: [
          {
            height: props.docDetail.meta[0].height,
            width: props.docDetail.meta[0].width,
            x1: props.docDetail.meta[0].x1,
            x2: props.docDetail.meta[0].x2,
            y1: props.docDetail.meta[0].y1,
            y2: props.docDetail.meta[0].y2,
            pageNumber: props.docDetail.meta[0].pageNumber,
          },
        ],
        pageNumber: props.docDetail.meta[0].pageNumber,
      },

      id: props.docDetail.meta[0].id.replace("/meta#", ""),
      isOutLine: true,
    });
  }

  if (outLine) {
    outLine.forEach((element) => {
      tableContents.push({
        comment: {
          emoji: "",
          text: "",
        },
        content: {
          text: element.heading_s,
        },
        position: {
          boundingRect: getBoundingRect(element),
          rects: getRectValues(element),
          pageNumber:
            element.meta.length > 0
              ? element.meta[0].pageNumber
              : element.meta.pageNumber,
        },
        id: element.id.replace("/outline#", ""),
        isOutLine: true,
      });
    });
    tableContents.sort(function (a, b) {
      return a.position.boundingRect.y1 - b.position.boundingRect.y1;
    });
    tableContents.sort(function (a, b) {
      return (
        a.position.boundingRect.pageNumber - b.position.boundingRect.pageNumber
      );
    });
  }



  const parseIdFromHash = () => document.location.hash.slice("#highlight-".length);

  console.log(`highlightText`, parseIdFromHash());
  useEffect(() => {
    window.addEventListener("hashchange", scrollToHighlightFromHash, false);
  }, [props.fileLinkRedux]);

  useEffect(()=>{
    const id = props.docDetail.id;
    const summary = props.docDetail?.result?.summary;
    
    getHighlight({
          parentId: id,
          text: summary,
          query: props.searchedWord,
        }).then((response) => {
          setLoading(false);
          const highlight_id = response.data.response.docs[0].id;
          console.log(`highlight_id`, highlight_id);
          const highlight = {
            comment: {
              emoji: "",
              text: "",
            },
            content: {
              text: response.data.response.docs[0].title[0],
            },
            position: {
              boundingRect: {
                height: response.data.response.docs[0].meta[0].height,
                width: response.data.response.docs[0].meta[0].width,
                x1: response.data.response.docs[0].meta[0].x1,
                x2: response.data.response.docs[0].meta[0].x2,
                y1: response.data.response.docs[0].meta[0].y1,
                y2: response.data.response.docs[0].meta[0].y2,
                pageNumber: response.data.response.docs[0].meta[0].pageNumber,
              },
              rects: [
                {
                  height: response.data.response.docs[0].meta[0].height,
                  width: response.data.response.docs[0].meta[0].width,
                  x1: response.data.response.docs[0].meta[0].x1,
                  x2: response.data.response.docs[0].meta[0].x2,
                  y1: response.data.response.docs[0].meta[0].y1,
                  y2: response.data.response.docs[0].meta[0].y2,
                  pageNumber: response.data.response.docs[0].meta[0].pageNumber,
                },
              ],
              pageNumber: response.data.response.docs[0].meta[0].pageNumber,
            }
            ,
            id: response.data.response.docs[0].id,
            isOutLine: false,
          }
          setHighlightText([highlight]);
            
          

          // navigate(`/searchResultDetail/ingested/${id}#highlight-${response.data.response.docs[0].id}`);
        }).catch((error) => {
          console.log(`error`, error);
        });

  },[])

  const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());
    console.log(`highlight`, highlight);
    if (highlight) {

      scrollViewerTo.current(highlight);
    }
  };

  useEffect(() => {
    if (highlightText.length > 0) {
      console.log(`highlightText`, highlightText);
      
      
    }
  }, [highlightText]);

  const getHighlightById = (id) => {
    let highlightContent = tableContents.find(
      (highlight) => highlight.id === id
    );
    console.log(`highlightContent`, highlightContent, `tableContents`, tableContents);  
    if (!highlightContent) {

      highlightContent = tableContents.find((highlight) => highlight.id === id);
    }
    return highlightContent;
  };



  return (
    <div className="App" style={{ display: "flex", height: "100vh" }}>
      <Sidebar highlights={highlightText} tableContents={tableContents} />

      <div>
        {!loading?<PdfLoader ref={pdfRef} url={props.fileLinkRedux} beforeLoad={<Spinner />}>
          {(pdfDocument) => {
            
            return <PdfHighlighter
              pdfDocument={pdfDocument}

              onScrollChange={() => { }}
              onSelectionFinished={() => { }}
              scrollRef={(scrollTo) => {
                scrollViewerTo.current = scrollTo;
                scrollViewerTo.current(highlightText[0]);
                // scrollToHighlightFromHash();
              }}
              highlightTransform={(
                highlight,
                index,
                setTip,
                hideTip,
                viewportToScaled,
                screenshot,
                isScrolledTo
              ) => {
                const isTextHighlight = !Boolean(
                  highlight.content && highlight.content.image
                );
                const component = isTextHighlight ? (
                  <Highlight ref={pdfRef}
                    position={highlight.position}
                    comment={highlight.comment}
                  />
                ) : (
                  <AreaHighlight
                    // isScrolledTo={isScrolledTo}
                    highlight={highlight}

                  // onChange={(boundingRect) => {
                  //   updateHighlight(
                  //     2,
                  //     { boundingRect: viewportToScaled(boundingRect) },
                  //     { image: screenshot(boundingRect) }
                  //   );
                  // }}
                  />
                );

                return (
                  <Popup
                    popupContent={<HighlightPopup {...highlight} />}
                    // onMouseOver={(popupContent) =>
                    //   setTip(highlight, (highlight) => popupContent)
                    // }
                    // onMouseOut={hideTip}
                    onMouseOver={() => { }}
                    key={index}
                    children={component}
                  />
                );
              }}
              highlights={
                highlightText
              }
            />
          }
          }
        </PdfLoader>:<Spinner />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fileLinkRedux: state.fileLinkRedux,
    outLineRedux: state.outLineRedux,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps)(PdfHighlighterPage);