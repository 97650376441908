import { combineReducers } from "redux";


const searchResultsReducer = (searchResult = "", action) => {
  if (action.type === "SAVE_SEARCH_RESULT") {
    searchResult = action.payload;
  }

  return searchResult;
};
const searchResultsAuthDocsReducer = (searchResultAuthDocs = "", action) => {
  if (action.type === "SAVE_SEARCH_AUTHDOCS_RESULT") {
    searchResultAuthDocs = action.payload;
  }

  return searchResultAuthDocs;
};
const pinDocReducer = (pinnedSearchResult = [], action) => {
  switch (action.type) {
    case "PIN_SEARCH_RESULT":
      // Ensure you don't duplicate pinned documents
      if (!pinnedSearchResult.find(doc => doc.id === action.payload.id)) {
        return [...pinnedSearchResult, action.payload];
      }
      return pinnedSearchResult;
      
    case "UNPIN_SEARCH_RESULT":
      return pinnedSearchResult.filter((doc) => doc.id !== action.payload);
      
    default:
      return pinnedSearchResult;
  }
};



const saveSearchWordReducer = (SearchWord = "", action) => {
  if (action.type === "SAVE_SEARCH_WORD") {
    SearchWord = action.payload;
  }

  return SearchWord;
};

const saveKnowledgeSourceFilterReducer = (knwldgeSrc = "", action) => {
  if (action.type === "SAVE_KNWLDG_SRC") {
    knwldgeSrc = action.payload;
  }

  return knwldgeSrc;
};

const saveFilteroutputlengthReducer = (filteroplength = "", action) => {
  if (action.type === "SAVE_FILTER_OP_LENGTH") {
    filteroplength = action.payload;
  }

  return filteroplength;
};
const saveRelatedEquipmentFilterReducer = (relatedEquipment = "", action) => {
  if (action.type === "SAVE_RLTD_EQPMNT") {
    relatedEquipment = action.payload;
  }

  return relatedEquipment;
};
const savefileLinkReducer = (fileLink = "", action) => {
  if (action.type === "STORE_FILELINK") {
    fileLink = action.payload;
  }

  return fileLink;
};
const saveOutlineReducer = (outline = "", action) => {
  if (action.type === "STORE_OUTLINE") {
    outline = action.payload;
  }

  return outline;
};
const saveDocDetailReducer = (docDetail = {}, action) => {
  if (action.type === "STORE_DOC_DETAIL") {
    docDetail = action.payload;
  }

  return docDetail;
};
const setFeatureFlagReducer = (featureFlag = [], action) => {
  if (action.type === "SET_FEATURE_FLAG") {
    featureFlag = action.payload;
  }
  return featureFlag;
};

const setEquipments = (equipments = [], action) => {
  if (action.type === "SET_EQUIPMENTS") {
    equipments = action.payload;
  }
  return equipments;
};

const setTypes = (types = [], action) => {
  if (action.type === "SET_TYPES") {
    types = action.payload;
  }
  return types;
};

const setPinnedSearches = (pinnedSearches = [], action) => {
  if (action.type === "SET_PINNEDSEARCH") {
    pinnedSearches = action.payload;
  }
  return pinnedSearches;
};
const totalDocReducer = (totalDoc = 0, action) => {
  if (action.type === "SET_TOTAL_DOC") {
    totalDoc = action.payload;
  }

  return totalDoc;
};
// const feedbackViewReducer = (feedback = "", action) => {
//   if (action.type === "SET_ACTIVE_PAGE") {
//     feedback = action.payload;
//   }

//   return feedback;
// };
const activePageReducer = (activePage = 1, action) => {
  if (action.type === "SET_ACTIVE_PAGE") {
    activePage = action.payload;
  }

  return activePage;
};

const addingnewFilter = (filter = "", action) => {
  if (action.type === "ADD_FILTER") {
    filter = action.payload;
  }
  return filter;
};

const searchIdReducer = (searchId = "", action) => {
  if (action.type === "SET_SEARCH_ID") {
    searchId = action.payload;
  }

  return searchId;
};

const addingnewPath = (path = "", action) => {
  if (action.type === "ADD_PATH") {
    path = action.payload;
  }
  return path;
};

const saveQAContentReducer = (content = "", action) => {
  if (action.type === "SAVE_QA_CONTENT") {
    content = action.payload;
  }
  return content;
};
const toasterReducer = (toasterLoader = false, action) => {
  if (action.type === "SET_TOASTER") {
    toasterLoader = action.payload;
  }
  return toasterLoader;
};

const spellcheckReducer = (spellcheck = "", action) => {
  if (action.type === "SAVE_SPELL_CORRECTION") {
    spellcheck = action.payload;
  }
  return spellcheck;
}
export default combineReducers({
  searchResult: searchResultsReducer,
  searchResultAuthDocs: searchResultsAuthDocsReducer,
  spellcheck: spellcheckReducer,
  docDetail: saveDocDetailReducer,
  pinnedSearchResult: pinDocReducer,
  searchedWord: saveSearchWordReducer,
  knowledgeSrcFilter: saveKnowledgeSourceFilterReducer,
  relatedEquipmentFilter: saveRelatedEquipmentFilterReducer,
  filteroutputlength: saveFilteroutputlengthReducer,
  fileLinkRedux: savefileLinkReducer,
  outLineRedux: saveOutlineReducer,
  featureFlag: setFeatureFlagReducer,
  documentequipments: setEquipments,
  documenttypes: setTypes,
  totalDoc: totalDocReducer,
  activePage: activePageReducer,
  pinnedSearches: setPinnedSearches,
  newFilter: addingnewFilter,
  newPath: addingnewPath,
  QAContentRedux: saveQAContentReducer,
  toaster: toasterReducer,
  searchId: searchIdReducer,
  // feedbackList: feedbackViewReducer,
});
