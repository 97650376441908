import React, { useState, useEffect } from "react";
import { featureFlagAPi, logUserAction } from "./components/apiCall";
import { setFeatureflag, setToaster } from "./redux/actions/index";
import Dashboard from "./pages/Dashboard";
import { Routes, Route, useLocation } from "react-router-dom";
import SearchResultPage from "./pages/SearchResultPage";
import SearchResultDetailPage from "./pages/SearchResultDetailPage";
import AllPinnedSearchPage from "./pages/AllPinnedSearchPage";
import Toaster from "./components/Toaster/Toaster";
import { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, previewPageGA } from "./googleAnalytics/Analytics";
import Banner from "./components/Banner/BannerPop";
import { connect } from "react-redux"; // Import connect


function App(props) {
  const location = useLocation();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const bannerShownTimestamp = localStorage.getItem('bannerShownTimestamp');
    const bannerClosed = localStorage.getItem('bannerClosed') === 'true';
    const sevenDaysInMilliseconds = 14 * 24 * 60 * 60 * 1000;
    const now = new Date().getTime();

    if (bannerClosed) {
      setShowBanner(false);
      return;
    }

    if (bannerShownTimestamp) {
      const timeDiff = now - parseInt(bannerShownTimestamp, 10);
      if (timeDiff < sevenDaysInMilliseconds) {
        setShowBanner(true);
      } else {
        setShowBanner(false);
        localStorage.removeItem('bannerShownTimestamp');
      }
    } else {
        setShowBanner(true);
        localStorage.setItem('bannerShownTimestamp', now.toString());
    }

    logUserAction({
      route: 'login',
    });
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      logUserAction({
        route: 'logout',
      });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    InitializeGoogleAnalytics();
    TrackGoogleAnalyticsEvent( 'Signed in with user ID', 'sampleAction' );
    featureFlagAPi()
      .then((data) => {
        let featureObj = {};
        for (let i = 0; i < data.data.features.length; i++) {
          featureObj[data.data.features[i].featureId] =
            data.data.features[i].state === "enabled" ? true : false;
        }
        props.setFeatureflag(featureObj);
      })
      .catch(() => {
        props.setToaster(true);
      });
  }, []);

  useEffect(() => {
    console.log('Current Path:', location.pathname);
    previewPageGA(location.pathname);
  }, [location.pathname]);

  const handleSetShowBanner = (show) => {
    setShowBanner(show);
    localStorage.setItem('bannerClosed', (!show).toString());
  };

  return (
    <div className="z-0 app">
      <Banner showBanner={showBanner} setShowBanner={handleSetShowBanner} />
      {props.toaster && <Toaster />}
      <Routes>
        <Route exact path="/" element={<Dashboard />}></Route>
        <Route
          exact
          path="/searchResult"
          element={<SearchResultPage />}
        ></Route>
        <Route
          exact
          path="/searchResultDetail/:type/:id"
          element={<SearchResultDetailPage />}
        ></Route>
        <Route
          exact
          path="/allpinnedsearches"
          element={
            <div>
              <AllPinnedSearchPage />
            </div>
          }
        />
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    toaster: state.toaster,
  };
};

export default connect(mapStateToProps, {
  setFeatureflag,
  setToaster,
})(App);