import React, { useState } from "react";
import { Modal } from "semantic-ui-react";

const Mediafullscreen = ({ media }) => {
  const mediaTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/JPG",
    "image/JPEG",
    "image/PNG",
    "png",
    "PNG",
    "jpeg",
    "JPEG",
    "jpg",
    "JPG",
  ];
  return (
    <>
      {media.type ? (
        <div className="modal-action-image">
          {mediaTypes.includes(media.type) ? (
            <img
              className="image-fullscreen"
              src={URL.createObjectURL(media)}
              alt="image"
            />
          ) : (
            <video preload="metadata" className="image-fullscreen" controls>
              <source
                className="photo"
                src={URL.createObjectURL(media)}
                alt=""
              />
            </video>
          )}
        </div>
      ) : (
        <div className="modal-action-image">
          <img className="image-fullscreen" src={media.url} alt="image" />
        </div>
      )}
    </>
  );
};

export default Mediafullscreen;
