import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Card } from "semantic-ui-react";

import Navbar from "../components/Navbar/Navbar";
import { Modal, Button } from "semantic-ui-react";
import "../assets/index.css";
import {
  setPinnedSearches,
  storeDocDetail,
  storeOutline,
  storeFileLink,
  addPath,
  setToaster,
} from "../redux/actions";
import backarrow from "../assets/images/backarrow.png";
import { useNavigate } from "react-router";
import { deletePinDocument } from "../components/apiCall";
import PinnedDocs from "../components/PinnedDocs/PinnedDocs";
import PinnedDocsHeader from "../components/PinnedDocs/PinnedDocsHeader";
import PinnedDocsPopup from "../components/PinnedDocs/PinnedDocsPopup";
import KeycloakUserService from "../keycloak/KeycloakUserService";
import { appInsights } from "../utils/appInsights.js";
function AllPinnedSearchPage(props) {
  const [popupOn, setPopupon] = useState(false);
  const navigate = new useNavigate();
  const [contentPopup, setContentpopup] = useState({});
  const [pinnedDocs, setPinnedDocs] = useState(props.pinnedSearches);
  props.addPath("/allpinnedsearches");

  useEffect(() => {
    console.log("pinned data", pinnedDocs);
  },[])

  const handleRemove = (pinId) => {
    deletePinDocument(pinId)
      .then(() => {
        setPinnedDocs((result) =>
          result.filter((item) => item.documentId !== pinId)
        );
        props.setPinnedSearches(
          pinnedDocs.filter((item) => item.documentId !== pinId)
        );
        setPopupon(false);
        appInsights.trackEvent({ 
            name: "UnpinDocument", 
            properties: { 
              pinId: pinId,
              username: KeycloakUserService.getUsername() 
            } 
        });
      })
      .catch(() => {
        props.setToaster(true);
      });
  };

  const handlepopupOn = (data) => {
    setContentpopup(data);
    setPopupon(true);
  };

  const handleBackBtn = () => {
    navigate("/");
  };

  return (
    <>
      <Navbar />
      
      <div className="pinnedSection ">
        <div className="p-1 w-16 mt-3 mb-3 ml-[0.6rem] md:ml-0 bg-white text-blue-600 border-[#0971CE] border-2 rounded-md">
          <div onClick={(e) => handleBackBtn()}>
            <button className=" text-xs  p-1 pl-1 flex gap-y-2">
              <img
                className=" h-3  mt-0.5"
                src={backarrow}
                alt="arrow_back_ios icon"
              ></img>
              <p className="text-xs ml-1.5  text-[#0971CE] ">Back</p>
            </button>
          </div>
        </div>
        <PinnedDocsHeader length={pinnedDocs.length} atPinnedSearch={true} />

        {pinnedDocs.length === 0 ? (
          <div className="centered-card">
            <div className="card-content">
              <p>There are currently no pinned documents to display</p>
            </div>
          </div>
        ) : (
          <Card.Group itemsPerRow={4} className="cardgroup">
            {pinnedDocs?.map((data) => {
              return (
                <>
                  {data.searchResults && (
                    <PinnedDocs data={data} handlepopupOn={handlepopupOn} />
                  )}
                </>
              );
            })}
          </Card.Group>
        )}

        {/* popup for unpin from all pinned document page */}
        {popupOn && (
          <Modal
            className="modal-upload-unpin"
            open={popupOn}
            onClose={() => setPopupon(false)}
            size="tiny"
          >
            <PinnedDocsPopup contentPopup={contentPopup?.searchResults} />

            <div className="feedback-btn-wrap">
              <Button
                className="secondaryButton"
                onClick={() => {
                  setPopupon(false);
                }}
              >
                No, Cancel
              </Button>
              <Button
                className="primaryButton"
                onClick={(e) => handleRemove(contentPopup?.documentId)}
              >
                Yes, Unpin
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    pinnedSearches: state.pinnedSearches,
  };
};

export default connect(mapStateToProps, {
  setPinnedSearches,
  storeDocDetail,
  storeFileLink,
  storeOutline,
  addPath,
  setToaster,
})(AllPinnedSearchPage);
