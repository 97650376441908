import React from "react";

const Banner = ({ showBanner, setShowBanner }) => {
  if (!showBanner) {
    return null;
  }

  const handleClose = () => {
    setShowBanner(false);
  };

  return (
    <div className="bg-[#5CAAF0] p-3 text-center relative">
      <div className="flex flex-col justify-start items-start">
        <p className="text-sm text-white mb-2 text-left">
          Thank you for using LexX. We’ve recently upgraded our platform to enhance your experience and are actively monitoring system performance. These improvements focus on refining interactions, improving how results are displayed, and improving response times. Before exiting a document, please take a moment to let us know its usefulness by clicking "Yes" or "No." buttons at the top of each document. Your feedback is invaluable in helping us optimize the system for you.
        </p>
        {/* <p className="text-sm text-white text-left">
          Before exiting a document, please take a moment to let us know its usefulness by clicking "Yes" or "No." buttons at the top of each document. Your feedback is invaluable in helping us optimize the system for you.
        </p> */}
        <button
          className="absolute top-1 right-2 bg-transparent border-none text-white hover:text-gray-100 focus:outline-none text-lg cursor-pointer"
          onClick={handleClose}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default Banner;