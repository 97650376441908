import React from "react";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import Navbar from "../components/Navbar/Navbar";
import WasHelpFull from "../components/WasThisHelpful/WasHelpFull";
import ResultSearchDetailBox from "../components/SearchDetail/ResultSearchDetailBox";
import { getProcedure, getHighlight, getSasUrl, getAllPinedMessage, getAllPinnedDoc } from "../components/apiCall";
import {
  storeDocDetail,
  storeOutline,
  storeFileLink,
  setPinnedSearches
} from "../redux/actions";
import { Loader } from "semantic-ui-react";


const SearchResultDetailPage = (props) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, type } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch the document using the ID
    let docData;
    const fetchDocument = async () => {
      console.log('fetching document', id, type);
      let promises
      try {
        setLoading(true);
        if (type === 'ingested') {
          if (!props.docDetail?.file_link) {
            console.log('fetching document bruh', id, type);
            promises = [getProcedure({ params: { id, type } })];
            const responses = await Promise.all(promises);
            setLoading(false);
            const highlight_meta = props.docDetail ? props.docDetail.meta : responses[0].data.outline[0].meta;
            const response = responses[0]
            console.log(response.data, 'response');
            docData = {
              result: response.data,
              id: response.data.id,
              rootId: response.data.id,
              title: response.data.title,
              file_link: response.data.file_link,
              equipment: response.data.equipment[0],
              type: response.data.type,
              fileName: response.data.file_name,
              meta: highlight_meta,
              dual: response.data.dual
            }
          }
          else {
            docData = props.docDetail;
          }

          getSasUrl({
            url: docData.file_link,
            directory: docData.directory,
          }).then((res) => {
            dispatch(storeFileLink(res.data.convertedUrl));
          })
          dispatch(storeDocDetail(docData));
          dispatch(storeOutline(JSON.stringify(docData.result.outline)));
        } else {
          console.log('fetching document bruh', id, type);
          if (!props.docDetail?.authorData ) {

            const response = await getProcedure({ params: { id, type } });
            response.data.summary = props.docDetail.summary;
            console.log(props.data, 'response');
            dispatch(storeDocDetail({
              authorData: JSON.stringify(response.data),
              authdoc: true,
            }));
          }
          else if(props.docDetail?.authorData && !JSON.parse(props.docDetail?.authorData)?.procedureDescription){
            const response = await getProcedure({ params: { id, type } });
            response.data.summary = props.docDetail.summary;
            console.log(props.data, 'response');
            dispatch(storeDocDetail({
              authorData: JSON.stringify(response.data),
              authdoc: true,
            }));
          }
          else {

            console.log('bruh bruh', props.docDetail);
            docData = props.docDetail;
            console.log('bruh bruh', docData);
            dispatch(storeDocDetail(docData));
          }
        }
        // set pinned docs
        let docfrommongo = [];
        let docfromsolr = [];
        const getAllPinedMessageRes = await getAllPinedMessage();
        console.log("getAllPinedMessageRes", getAllPinedMessageRes);
        const idArray = getAllPinedMessageRes.data.data.map(
          (item) => item.documentId
        );
        docfrommongo = getAllPinedMessageRes.data.data;
        const query = {
          q: idArray,
          filter: [],
        };
        getAllPinnedDoc(query).then((res) => {

          docfromsolr = res.data.response.docs;
          docfrommongo.map((data) => {
            let pindata = docfromsolr.filter((obj) => obj.id === data.documentId);
            data.searchResults = pindata[0];

          });
          // setPinnedDocs(docfrommongo);
          props.setPinnedSearches(docfrommongo);
        }).catch((err) => {
          console.log(err);
        });

      } catch (error) {
        console.error('Error fetching document:', error);
      }
      setDataLoaded(true);
    };
    id && fetchDocument();
  }, []);

  // main search result detail page where all details of items will be displayed
  return (
    <div className="bg-[#FAFAFA]">
      {dataLoaded && <Navbar />}
      {!dataLoaded && <Loader active />}
      <WasHelpFull />

      {dataLoaded && <ResultSearchDetailBox />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchedWord: state.searchedWord,
    pinnedSearches: state.pinnedSearches,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps, {
  setPinnedSearches,
  storeDocDetail,
  storeOutline,
  storeFileLink,
})(SearchResultDetailPage);